<template>
  <div class="perfect-archiving-wrap">
    <div class="top-box">
      <div class="title-box">
        <img src="@/assets/img/icon-3.png" alt="" /> 完美存档
        <span
          >(说明：列表显示平台支持的完美存档游戏，并显示游戏的完美存档数量)</span
        >
      </div>
      <div class="input-box">
        <el-input
          placeholder="请输入您想要找的游戏"
          v-model="keyword"
          class="input-with-select"
          @keydown.enter.native="handleSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            type="primary"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </div>
    </div>

    <div class="file-wrap">
      <a
        href="javascript:;"
        class="game-file"
        v-for="(item, index) in PerfectArchives"
        :key="index"
        v-if="item.gameInfoEntity"
        @click.stop.prevent="more(item)"
      >
        <div class="game-img">
          <img :src="item.gameInfoEntity.Img" alt="" />
        </div>
        <div class="game-name">
          <p class="name ellipsis">{{ item.gameInfoEntity.Name }}</p>
          <p class="english-name ellipsis">
            {{ item.gameInfoEntity.EnglishName }}
          </p>
          <span class="icon"
            >保存了{{ item.PerfectArchives.length }}个存档</span
          >
        </div>
        <div class="file-name">
          <div
            v-for="(o, index) in item.PerfectArchives"
            :key="index"
            v-show="index < 3"
            class="ellipsis"
          >
            <img src="@/assets/img/down.png" alt="" /> {{ o.ArchiveName }}
          </div>
        </div>
        <div class="operation">
          <el-button size="small" @click.stop.prevent="more(item)"
            >更 多</el-button
          >
        </div>
      </a>
    </div>

    <!-- 分页 -->
    <div class="pagination-box" v-if="PerfectArchives.length">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
      <!--sizes,  :page-sizes="[8, 16, 32, 64]" -->
    </div>

    <div class="no-data" v-if="PerfectArchives.length == 0">
      <img src="@/assets/img/noData.png" alt="" />
      <p>暂无存档</p>
    </div>

    <el-dialog
      v-if="gameObj && gameObj.gameInfoEntity"
      :title="gameObj.gameInfoEntity.Name + ' - 完美存档'"
      :visible.sync="dialogTableVisible"
      custom-class="file-dialog"
      width="952px"
    >
      <el-table :data="gridData" height="400">
        <el-table-column property="date" label="存档名称" width="200">
          <template slot-scope="scope">
            {{ scope.row.ArchiveName }}
          </template>
        </el-table-column>
        <el-table-column
          property="name"
          label="存档图片"
          width="160"
          align="center"
        >
          <template slot-scope="scope">
            <div class="img-box">
              <el-carousel
                height="64px"
                indicator-position="none"
                v-if="scope.row.PerfectArchiveImgDtos.length"
                :autoplay="false"
              >
                <el-carousel-item
                  v-for="(item, index) in scope.row.PerfectArchiveImgDtos"
                  :key="index"
                >
                  <el-image
                    :src="item.ImgUrl"
                    width="240px"
                    height="64px"
                    alt=""
                    fit="cover"
                    :preview-src-list="scope.row.previewSrcList"
                  />
                </el-carousel-item>
              </el-carousel>
              <span class="icon"
                >{{ scope.row.PerfectArchiveImgDtos.length }}张</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column property="date" label="存档描述" width="300">
          <template slot-scope="scope">
            {{ scope.row.Describe }}
          </template>
        </el-table-column>
        <el-table-column
          property="address"
          label="操作"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <el-button size="small" @click="downLoad(scope.row)"
              >下载存档</el-button
            >
            <el-button size="small" @click="handleClick"
              >登录steam开始游戏</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { GetGameListNew } from "@/api";
import { mapGetters } from "vuex";
import cMethods from "@/util/cMethods.js";
import { myMixins } from "@/util/mixins.js";

export default {
  mixins: [myMixins],
  computed: {
    ...mapGetters(["isLogin", "userInfo"]),
  },
  data() {
    return {
      gridData: [],
      dialogTableVisible: false,
      PerfectArchives: [],
      pageSize: 5,
      pageIndex: 1,
      total: 0,
      gameObj: null,
      keyword: "",
      gameId: null,
    };
  },
  mounted() {
    this._GetGameList();
    this.$statistic(["A", "完美存档", "页面", "展现量"]);
  },
  methods: {
    // 下载
    downLoad(row) {
      if (this.isLogin && this.userInfo) {
        if (this.gameObj) {
          this.gameId = this.gameObj.GameID;
          if (
            typeof this.gameId == "string" &&
            this.gameId.indexOf("GM") > -1
          ) {
            this.gameId = parseInt(this.gameId.replace("GM", ""));
          }
          this.$confirm("请先关闭游戏和备份当前电脑游戏存档再进行下载！", {
            confirmButtonText: "下载并替换存档",
            distinguishCancelAndClose: true,
          })
            .then(() => {
              cMethods.Form_jsDownSaveForTQ(this.gameId, row.ArchiveUrl);
            })
            .catch((action) => {});
        }
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 获取游戏列表
    async _GetGameList() {
      let params = {
        source: 3,
        keyword: this.keyword,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        labels: "",
        platformId: "",
        isPerfectArchive: true,
      };

      let res = await GetGameListNew(params);
      // console.log(res);
      if (res.StatusCode == 200) {
        this.PerfectArchives = res.Items;
        this.total = res.Total;

        if (this.$route.query.gameId) {
          this.gameId = this.$route.query.gameId;
          // this.Per
        }
      }
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetGameList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetGameList();
      // this.$statistic(["B", "免费玩页面", "分页点击", "统计量"]);
    },
    more(item) {
      this.dialogTableVisible = true;
      this.gameObj = item;
      this.gridData = item.PerfectArchives;

      if (this.gridData.length) {
        this.gridData.map((i) => {
          let arr = [];
          if (i.PerfectArchiveImgDtos.length) {
            i.PerfectArchiveImgDtos.map((o) => {
              arr.push(o.ImgUrl);
            });
          }
          i.previewSrcList = arr;
        });
      }
    },
    handleSearch() {
      this.pageIndex = 1;
      this._GetGameList();
    },
    handleClick() {
      this.$statistic(["B", "完美存档页面", "使用按钮", "点击量"]);
      this.$router.push(`/gameDetail/${this.gameObj.ProductID}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.perfect-archiving-wrap {
  padding: 20px;
  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-box {
      display: flex;
      align-items: center;
      font-size: 22px;
      @include font_col3();
      img {
        margin-right: 5px;
      }
      span {
        margin-left: 20px;
        font-size: 15px;
        @include font_col9();
      }
    }
    .input-box {
      box-sizing: border-box;
      ::v-deep .el-input-group__append {
        border: none !important;
      }
      .input-with-select {
        .el-input__inner {
          border: 1px solid #e6e6e6;
          background-color: #fff !important;
        }
        ::v-deep .el-input__inner:focus {
          border-color: #ff3636 !important;
        }
        .el-button--primary {
          border-radius: 0px 4px 4px 0;
          color: #fff;
          background-color: #ff3636 !important;
          border-color: #ff3636 !important;
          padding: 13px 20px;
        }
      }
    }
  }
  .file-wrap {
    .game-file {
      margin-top: 10px;
      padding: 10px 20px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      @include item_bg_col();
      line-height: 24px;
      .game-img {
        margin-right: 10px;
        img {
          width: 190px;
          height: 89px;
          display: block;
        }
      }
      .game-name {
        // width: 300px;
        .name {
          width: 300px;
          @include font_col3();
          font-size: 20px;
        }
        .english-name {
          width: 300px;
          @include font_col9();
          font-size: 16px;
        }
        .icon {
          padding: 0px 8px;
          display: inline-block;
          font-size: 12px;
          color: #fff;
          background: linear-gradient(90deg, #ff3636 0%, #ff765a 100%);
          border-radius: 2px;
        }
      }
      .file-name {
        width: 400px;
        @include font_col6();
        img {
          margin-right: 5px;
        }
      }
      // .file-size {
      //   width: 120px;
      //   @include font_col6();
      // }
      .operation {
        width: 120px;
        text-align: right;
        .el-button {
          border: none;
          // background: linear-gradient(0deg, #ff4f4f 0%, #ff7878 100%);
          // color: #fff;
          color: $red_col;
          border: 1px solid $red_col;
          background: transparent;
          &:hover {
            color: #fff;
            background: $red_col;
          }
        }
      }
    }
  }

  .pagination-box {
    margin-top: 10px;
    text-align: center;
  }

  .no-data {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 10px;
      font-size: 16px;
      @include font_col3();
    }
  }
}
</style>

<style lang="scss">
.file-dialog {
  .el-dialog__body {
    padding: 10px !important;
    .el-table .el-table__cell {
      padding: 5px 0 !important;
    }
    .img-box {
      position: relative;
      .icon {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99;
        font-size: 12px;
        color: #fff;
      }
    }
    .el-button {
      border: none;
      // background: linear-gradient(0deg, #ff4f4f 0%, #ff7878 100%);
      // color: #fff;
      color: $red_col;
      border: 1px solid $red_col;
      background: transparent;
      &:hover {
        color: #fff;
        background: $red_col;
      }
    }
  }
}
</style>